import IdService from './id_service';
import Utils from './utils';

const fbcCookieId = '_fbc';
const fbpCookieId = '_fbp';
const fbclidUrlParamName = 'fbclid';

export default class Fb {
  constructor() {
    this.fbc = IdService.getCookieValueById(fbcCookieId);
    this.fbp = IdService.getCookieValueById(fbpCookieId);
    this.fbclid = Utils.getUrlQueryParam(fbclidUrlParamName);
  }

  getJson() {
    return {
      fbc: this.fbc,
      fbp: this.fbp,
      fbclid: this.fbclid,
    };
  }
}
