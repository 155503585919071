import Authn from './authn';
import IdService from './id_service';
import Utils from './utils';
import NullTracker from './null-tracker';

export default class Tracker {
  constructor(
    { analytics = NullTracker, metaPixel = NullTracker },
    lighthouse,
    user,
    tealium,
    fb,
  ) {
    this.analytics = analytics;
    this.metaPixel = metaPixel;
    this.lighthouse = lighthouse;
    this.user = user;
    this.tealium = tealium;
    this.fb = fb;
    this.delayMs = 500;
  }

  /**
    Method to send event data to tracking service
  * */
  fireEvent(eventName, options = {}, callback = null) {
    if (!eventName) {
      throw new Error('eventName must be present!');
    }

    try {
      const newOptions = options;
      newOptions.auth_status = Authn.isAuthenticated();

      const eventId = IdService.getEventId(newOptions);

      this.analytics.track(
        'event',
        eventId,
        eventName,
        newOptions,
        this.user.getJson(),
        this.tealium.getJson(),
        this.getThirdPartyIds(),
      );

      this.metaPixel.track(
        'trackSingleCustom',
        eventId,
        eventName,
        newOptions,
        this.user.getJson(),
        this.tealium.getJson(),
        this.getThirdPartyIds(),
      );
    } catch (err) {
      console.log('tracker.FireEvent Error:', err);
    } finally {
      this._executeCallback(callback);
    }
  }

  /**
    Method to send user level data to tracking service
  * */
  setUserProperties(options, callback = null) {
    this.user.setProperties(options);
    this._executeCallback(callback);
  }

  /**
    Method to set tealium trace id
  * */
  setTraceId(traceId) {
    if (!traceId) {
      return;
    }
    this.tealium.setTraceId(traceId);
  }

  /**
    Method to check for the Lighthouse cookie and assign experiment values
  * */
  checkForExperimentCookie() {
    if (!this.lighthouse || !this.lighthouse.isCookieSet()) {
      return;
    }

    const lighthouseExperiments = this.lighthouse.getExperiments();
    this.user.setExperiments(lighthouseExperiments);
  }

  /**
    Method to send Page View event
  * */
  pageView(data = {}) {
    const eventId = IdService.getEventId(data);

    this.analytics.track(
      'page',
      eventId,
      'PageView',
      data,
      this.user.getJson(),
      this.tealium.getJson(),
      this.getThirdPartyIds(),
    );

    this.metaPixel.track(
      'trackSingle',
      eventId,
      'PageView',
      data,
      this.user.getJson(),
      this.tealium.getJson(),
      this.getThirdPartyIds(),
    );
  }

  /**
    Add experiment in the user attribute.
  * */
  addExperiment(experimentId, value) {
    if (!experimentId || !value) {
      throw new Error('experimentId and value should be present');
    }

    this.lighthouse.addExperiment(experimentId, value);
    this.user.addExperiment(experimentId, value);
  }

  /**
    Get the generated anonymous user id for the user
  * */
  getAnonymousId() {
    return this.user.getAnonymousId();
  }

  /**
    Get third party ids
  * */
  getThirdPartyIds() {
    return {
      fbc: this.fb.fbc,
      fbp: this.fb.fbp,
      fbclid: this.fb.fbclid,
    };
  }

  /**
    Method to execute methods given by a queue
  * */
  executeQueuedEvents(queue) {
    if (!queue || !Array.isArray(queue) || queue.length < 1) {
      return;
    }

    const trackerScope = this;
    queue.forEach((e) => {
      const methodName = e[0];
      const args = e.slice(1);
      trackerScope[methodName](...args);
    });
  }

  /**
    Method to execute callbacks
  * */
  _executeCallback(callback) {
    if (!Utils.isFunction(callback)) {
      return;
    }

    Utils.delay(this.delayMs).then(() => {
      callback();
    });
  }
}
