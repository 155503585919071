import axios from 'axios';
import snakeCaseKeys from 'snakecase-keys';
import pageInfo from './page';

export default class MsAnalytics {
  constructor(hostUrl, version) {
    this.hostUrl = hostUrl;
    this.version = version;
  }

  track(
    eventType,
    eventId,
    eventName,
    eventData,
    userData = {},
    thirdPartyIdsData = {},
  ) {
    const payload = this.formatPayload(
      eventType,
      eventId,
      eventName,
      eventData,
      userData,
      thirdPartyIdsData,
    );
    this._send(payload);
  }

  formatPayload(
    eventType,
    eventId,
    eventName,
    eventData,
    userData,
    thirdPartyIdsData,
  ) {
    const contextInfo = snakeCaseKeys(pageInfo());
    if (eventData && eventData.pageUrl) {
      contextInfo.page_url = eventData.pageUrl;
    }

    const payload = {
      event_id: eventId,
      type: eventType,
      context: contextInfo,
      user: snakeCaseKeys(userData),
      body: {
        event_name: eventName,
        data: snakeCaseKeys(eventData),
      },
      sent_at: new Date().toISOString(),
      _ids: thirdPartyIdsData,
    };

    payload.version = this.version;
    return payload;
  }

  _send(payload) {
    return axios.post(this.hostUrl, payload);
  }
}
