/* global COUNTRY_CODE */
import UAParser from 'ua-parser-js';

function getCanonicalUrl() {
  let canonical = '';
  const links = document.getElementsByTagName('link');
  for (let i = 0; i < links.length; i += 1) {
    if (links[i].getAttribute('rel') === 'canonical') {
      canonical = links[i].getAttribute('href');
    }
  }

  if (canonical) {
    return canonical;
  }

  return document.URL;
}

export default function pageInfo() {
  const { userAgent } = window.navigator;
  const uaParser = new UAParser(userAgent);

  const device = uaParser.getDevice();
  if (!device.type) {
    device.type = 'desktop';
  }

  let websiteCountry = '';
  if (COUNTRY_CODE) {
    websiteCountry = COUNTRY_CODE.toUpperCase();
  }

  return {
    title: document.title,
    pageUrl: document.URL,
    canonicalUrl: getCanonicalUrl(),
    referrer: document.referrer,
    userAgent,
    device,
    operatingSystem: uaParser.getOS(),
    browser: uaParser.getBrowser(),
    websiteCountry,
  };
}
