/* global ENVIRONMENT */
/* Removes curly braces from the lighthouse's cookie value */
const transformCookieData = (data) => {
  if (data) {
    return data.replace(/[{}\s]/g, '');
  }
  return data;
};

/* Converts strings with format: id1:value1, id2:value2 to JSON */
const transformCookieDataToJson = (data) => {
  const object = {};
  const dataArray = data.split(',');
  dataArray.forEach((e) => {
    const [key, value] = e.trim().split(':');
    if (key && value) {
      object[key] = value;
    }
  });

  return object;
};

/* Converts json data formatted by `transformCookieDataToJson` back to an array */
const jsonCookieDataToArray = (data) => {
  const resultArray = [];
  Object.entries(data).forEach(([key, value]) => {
    if (key && value) {
      resultArray.push(`${key}:${value}`);
    }
  });

  return resultArray;
};

const removeQuotes = (string) => string.replace(/(^"|"$)/g, '');

const delay = (delayMs) => new Promise((resolve) => {
  setTimeout(resolve, delayMs);
});

const isFunction = (object) => (!!object) && typeof (object) === 'function';

const randomInt = (minInt, maxInt) => minInt + Math.floor(maxInt * Math.random());

/* Returns the domain of the current page */
const getDomain = () => {
  if (ENVIRONMENT === 'development' || window.location.hostname === 'localhost') {
    return window.location.hostname;
  }

  try {
    const regex = /\w*\.?((moneysmart|mssgdev|mshkdev|mstwdev|msphdev)\.\w+)$/;
    const result = regex.exec(window.location.hostname);
    return result[1];
  } catch (err) {
    return window.location.hostname;
  }
};

const getUrlQueryParam = (paramName) => {
  const paramString = window.location.search;
  const urlSearchParams = new URLSearchParams(paramString);
  const paramValue = urlSearchParams.get(paramName);

  if (!paramValue) {
    return null;
  }

  return paramValue;
};

const removeField = (obj, path) => {
  const keys = path.split('.');
  const lastKey = keys.pop();
  let temp = obj;
  /* eslint-disable */
  for (const key of keys) {
    if (!(key in temp)) return false; // path does not exist
    temp = temp[key];
  }
  /* eslint-enable */
  if (lastKey in temp) {
    delete temp[lastKey];
    return true;
  }

  return false;
};

export default {
  delay,
  isFunction,
  jsonCookieDataToArray,
  randomInt,
  removeQuotes,
  transformCookieData,
  transformCookieDataToJson,
  getDomain,
  getUrlQueryParam,
  removeField,
};
