/* global META_PIXEL_ID, MS_ANALYTICS_URL, LIGHTHOUSE_COOKIE_ID, VERSION */

import 'core-js/stable/promise';
import 'core-js/stable/object/assign';
import 'core-js/stable/array/is-array';
import Lighthouse from './lighthouse';
import MetaPixel from './meta_pixel';
import MsAnalytics from './ms_analytics';
import Tracker from './tracker';
import User from './user';
import Fb from './fb';
import Tealium from './tealium';

const create = ({ data, isSpa }) => {
  const analytics = new MsAnalytics(MS_ANALYTICS_URL, VERSION);
  const metaPixel = new MetaPixel(META_PIXEL_ID, VERSION);
  const lighthouseInstance = new Lighthouse(LIGHTHOUSE_COOKIE_ID);
  const tracker = new Tracker(
    { analytics, metaPixel },
    lighthouseInstance,
    new User(),
    new Tealium(),
    new Fb(),
  );

  tracker.checkForExperimentCookie();
  if (!isSpa) {
    tracker.pageView(data);
  }

  tracker.executeQueuedEvents(window.tracker);
  return tracker;
};

/* eslint-disable */
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
/* eslint-enable */

const trackerConfig = window.trackerConfig || {};
window.tracker = create(trackerConfig);
