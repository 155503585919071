import Cookies from 'js-cookie';
import IdService from './id_service';
import Utils from './utils';

const traceIdCookieId = 'tealium_trace_id';

export default class Tealium {
  constructor() {
    this.traceId = IdService.getCookieValueById(traceIdCookieId);
  }

  setTraceId(traceId) {
    if (this.traceId === traceId) {
      return;
    }
    this.traceId = traceId;
    const domain = Utils.getDomain();
    Cookies.set(traceIdCookieId, traceId, { domain, expires: 730 });
  }

  getJson() {
    return {
      traceId: this.traceId,
    };
  }
}
