import IdService from './id_service';

export default class User {
  constructor(unknownUserId = null) {
    const anonymousId = IdService.getId();

    if (anonymousId === '' || !anonymousId) {
      throw new Error('anonymousId is required');
    }

    this.anonymousId = anonymousId;
    this.ampId = IdService.getAmpId();
    this.unknownUserId = unknownUserId;
    this.experiments = {};
  }

  setExperiments(experiments) {
    if (!experiments) {
      this.experiments = {};
      return;
    }

    this.experiments = experiments;
  }

  addExperiment(experimentId, experimentValue) {
    this.experiments[experimentId] = experimentValue;
  }

  setProperties(properties) {
    this.attributes = properties;
  }

  getAnonymousId() {
    return this.anonymousId;
  }

  getJson() {
    return {
      ...this.attributes,
      anonymousId: this.anonymousId,
      ampId: this.ampId,
      experiments: this.experiments,
      userId: IdService.getUserId(),
    };
  }
}
