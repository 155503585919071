import snakeCaseKeys from 'snakecase-keys';
import Utils from './utils';

export default class MetaPixel {
  constructor(metaPixelId, version) {
    this.metaPixelId = metaPixelId;
    this.version = version;
    this.blockedParams = [
      'additional_parameters.billing_address',
      'additional_parameters.shipping_address',
      'filters.loan_features',
    ];
    if (window.fbq) {
      window.fbq('init', this.metaPixelId);
    }
  }

  track(
    eventType,
    eventId,
    eventName,
    eventData,
    userData = {},
    tealiumData = {},
    thirdPartyIdsData = {},
  ) {
    const payload = this.formatPayload(
      eventData,
      userData,
      tealiumData,
      thirdPartyIdsData,
    );
    this._send(eventType, eventId, eventName, payload);
  }

  formatPayload(eventData, userData, tealiumData, thirdPartyIdsData) {
    const formattedEventData = snakeCaseKeys(eventData);

    // Drop event parameters that goes against Meta Business Tool Terms
    this.blockedParams.forEach((key) => Utils.removeField(formattedEventData, key));

    return {
      data: formattedEventData,
      user: snakeCaseKeys(userData),
      sent_at: new Date().toISOString(),
      tealium: snakeCaseKeys(tealiumData),
      version: this.version,
      _ids: thirdPartyIdsData,
    };
  }

  _send(eventType, eventId, eventName, payload) {
    return window.fbq(
      eventType,
      this.metaPixelId,
      eventName,
      payload,
      { eventID: eventId },
    );
  }
}
