/* global AUTHN_COOKIE_ID */

import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

const isAuthenticated = () => Cookies.get(AUTHN_COOKIE_ID) !== undefined;

const getUserId = () => {
  if (!isAuthenticated()) {
    return null;
  }

  const token = Cookies.get(AUTHN_COOKIE_ID);
  const decodedToken = jwtDecode(token);
  return decodedToken.sub;
};

export default { isAuthenticated, getUserId };
