import { Base64 } from 'js-base64';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import md5 from 'md5';
import queryString from 'query-string';
import url from 'url';
import { v4 as uuid } from 'uuid';
import Utils from './utils';

const ampLinkerId = 'ms_linker';
const msAnonymousCookieId = 'ms_anonymous_id';

const saveId = (id) => {
  const domain = Utils.getDomain();
  Cookies.set(msAnonymousCookieId, id, { domain, expires: 730 });
};

const getId = () => {
  const cookieAnonymousId = Cookies.get(msAnonymousCookieId);
  if (cookieAnonymousId) {
    const oldId = Utils.removeQuotes(cookieAnonymousId);
    saveId(oldId);
    return oldId;
  }

  const newId = uuid();
  saveId(newId);
  return newId;
};

const getAmpId = () => {
  const urlObj = url.parse(window.location.href);
  const queryParams = queryString.parse(urlObj.query);
  const rawLinkerValue = queryParams[ampLinkerId];
  if (!rawLinkerValue) {
    return null;
  }

  const [idValue] = rawLinkerValue.split('*').slice(-1);
  return Base64.decode(idValue);
};

const getUserId = () => {
  const cognitoCookiePrefix = 'CognitoIdentityServiceProvider';
  const cookies = Object.keys(Cookies.get());
  const lastAuthUserCookie = cookies.find((key) => key.includes(cognitoCookiePrefix) && key.includes('LastAuthUser'));

  if (lastAuthUserCookie) {
    const cognitoUserId = Cookies.get(lastAuthUserCookie);
    const idToken = cookies.find((key) => key.includes(cognitoCookiePrefix) && key.includes(`${cognitoUserId}.idToken`));

    return idToken ? jwtDecode(Cookies.get(idToken))['custom:uuid'] : null;
  }

  return null;
};

const getEventId = (data = {}) => `mse-${md5(JSON.stringify(data) + uuid())}`;

const getCookieValueById = (cookieId) => (
  Cookies.get(cookieId)
    ? Utils.removeQuotes(Cookies.get(cookieId))
    : null
);

export default {
  getId,
  getAmpId,
  getUserId,
  getCookieValueById,
  getEventId,
};
